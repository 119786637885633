<template>
  <v-container fluid class="pa-4">
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="hidden-md-and-up mb-3">
      <v-col cols="12">
        <user-profile-card></user-profile-card>
        <div class="text-center mt-3 mb-2">
          <support-us></support-us>
        </div>
        <div class="text-center">
          <feedback></feedback>
        </div>
      </v-col>
    </v-row>
    <group-nav :group="currentGroup"></group-nav>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserProfileCard from '@/components/users/UserProfileCard';
import SupportUs from '@/components/shared/SupportUs';
import Feedback from '@/components/shared/Feedback';
import GroupNav from '../../components/groups/GroupNav.vue';

export default {
  components: {
    UserProfileCard,
    SupportUs,
    Feedback,
    GroupNav
  },
  computed: {
    ...mapGetters(['currentGroup'])
  }
};
</script>
