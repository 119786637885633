<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-card color="white" v-if="group">
        <v-card-title class="text-center justify-center py-2">
          <v-avatar class="mr-3">
            <v-img v-if="group.imageUrl" contain :src="group.imageUrl"></v-img>
            <v-img v-else contain :src="noImageAvailbale"></v-img>
          </v-avatar>
          <span class="text-h5 app-dark-gray--text font-weight-light">
            {{ group.name }}
          </span>
          <v-chip color="app-yellow" text-color="white" class="pl-2 ml-4">
            <ruby-label
              :ruby="currentMemberRuby"
              textColor="white---text"
            ></ruby-label>
          </v-chip>
        </v-card-title>
        <v-tabs color="app-blue" grow>
          <v-tab :to="`/groups/${group.id}/dashboard`">
            <v-icon color="app-blue">mdi-view-dashboard</v-icon>
          </v-tab>
          <v-tab :to="`/groups/${group.id}/members`">
            <v-icon color="app-blue">mdi-account-multiple</v-icon>
          </v-tab>
          <v-tab v-if="isCreatorOrLeader" :to="`/groups/${group.id}/archive`">
            <v-icon color="app-blue">mdi-archive</v-icon>
          </v-tab>
        </v-tabs>
      </v-card>
    </div>
    <div v-else>
      <v-toolbar v-if="group" color="white" flat>
        <v-avatar class="mr-3">
          <v-img v-if="group.imageUrl" contain :src="group.imageUrl"></v-img>
          <v-img v-else contain :src="noImageAvailbale"></v-img>
        </v-avatar>
        <span class="text-h5 app-dark-gray--text font-weight-light">
          {{ group.name }}
        </span>
        <v-chip color="app-yellow" text-color="white" class="pl-2 ml-4">
          <ruby-label
            :ruby="currentMemberRuby"
            textColor="white---text"
          ></ruby-label>
        </v-chip>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="app-blue" text :to="`/groups/${group.id}/dashboard`">
            <v-icon left>mdi-view-dashboard</v-icon>
            {{ $t('common.dashboard') }}
          </v-btn>
          <v-btn color="app-blue" text :to="`/groups/${group.id}/members`">
            <v-icon left>mdi-account-multiple</v-icon>
            {{ $t('members.members') }}
          </v-btn>
          <v-btn
            v-if="isCreatorOrLeader"
            color="app-blue"
            text
            :to="`/groups/${group.id}/archive`"
          >
            <v-icon left>mdi-archive</v-icon>
            {{ $t('common.archive') }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RubyLabel from '../shared/RubyLabel';

export default {
  name: 'group-nav',
  components: {
    RubyLabel
  },
  props: {
    group: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...mapGetters(['isCreatorOrLeader']),
    noImageAvailbale() {
      return require('../../assets/images/no-image.png');
    },
    currentMemberRuby() {
      return this.group.currentMember.stats.ruby;
    }
  }
};
</script>
